<template>
  <a class="text-decoration-none"
     :href="item.is_mailto_link ? `mailto:${item.resource_link}?subject=${item.mailto_subject}` : item.resource_link"
     :target="item.open_new_tab ? '__blank' : '_self'"
     :id="item.vendor">
    <div class="marketing-item">
      <img :src="agentPhoto(item.logo)" height="100px" alt />
      <div class="marketing-item__title">{{ item.vendor }}</div>
      <div class="marketing-item__desc">{{ item.description }}</div>
    </div>
  </a>
</template>

<script>
export default {
  name: "MarketingItem",
  props: {
    item: {}
  },
  mounted() {
    if (this.$route.hash === '#lead') {
      document.getElementById("FreeRateUpdate").scrollIntoView({block: 'center', inline: 'center'});
    }
  }
};
</script>

<style lang="scss" scoped>
.marketing-item {
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 16px 20px;
  margin-bottom: 20px;
  transition: all 0.3s;
  text-decoration: none;
  cursor: pointer;
  height: 250px;
  &:hover {
    border: 1px solid #017aff;
  }
  &__title {
    color: #000000;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    margin: 8px 0 4px 0;
  }
  &__desc {
    color: rgba($color: #000000, $alpha: 0.7);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }

  img {
    max-width: 100%;
  }
}
</style>
