<template>
  <div class="marketing">
    <base-page-header :title="pageTitle" :back-link="backLink">
      <template #buttons>
        <div class="d-flex justify-content-end">
          <button v-if="isAdmin" class="btn btn-outline-dark" @click="$router.push({ name: 'marketing_resource_admin' })">Manage</button>
        </div>
      </template>
    </base-page-header>

    <div class="page-content-breadcrumbs">
      <router-link :to="{ name: 'resource_center' }">Resource Center</router-link>
      <span class="delimeter">/</span>
      <span>Marketing Resources</span>
    </div>

    <base-page-content>
      <template #content>
        <page-spinner v-if="ui.loading" />
        <div v-else>
          <!--Media category--->
          <div class="marketing-category">
            <div class="marketing-title">Media</div>
          </div>
          <div class="row mb-5">
            <div class="col-md-4 col-lg-3">
              <router-link :to="{ name: 'ad_wizard' }" class="text-decoration-none section-item-link">
                <div class="marketing-item">
                  <img src="@/assets/icons/ad-wizard-icon.svg" alt />
                  <div class="marketing-item__title">Ad Wizard</div>
                  <div class="marketing-item__desc">Create Magazine Ad</div>
                </div>
              </router-link>
            </div>
            <div class="col-md-4 col-lg-3 mt-3 mt-md-0">
              <router-link :to="{ name: 'business_card_generator' }" class="text-decoration-none section-item-link">
                <div class="marketing-item">
                  <img src="@/assets/icons/business-icon.svg" alt />
                  <div class="marketing-item__title">Business Card</div>
                  <div class="marketing-item__desc">Generate Business Card</div>
                </div>
              </router-link>
            </div>
            <div class="col-md-4 col-lg-3 mt-3 mt-lg-0">
              <router-link :to="{ name: 'house_flyer_editor' }" class="text-decoration-none section-item-link">
                <div class="marketing-item">
                  <img src="@/assets/icons/open-house-flyer-icon.svg" alt />
                  <div class="marketing-item__title">Open House Flyer</div>
                  <div class="marketing-item__desc">Marketing flyer for open house</div>
                </div>
              </router-link>
            </div>
            <div class="col-md-4 col-lg-3">
              <router-link :to="{ name: 'homebuyer_guide' }" class="text-decoration-none section-item-link">
                <div class="marketing-item">
                  <img src="@/assets/icons/icon-home-buyer-guide-flyer.svg" alt />
                  <div class="marketing-item__title">Flyer: Home Buyer Guide</div>
                  <div class="marketing-item__desc">Share Home Buyer Guide with clients</div>
                </div>
              </router-link>
            </div>
            <div class="col-md-4 col-lg-3 mt-3 mt-3">
              <router-link :to="{ name: 'yard_sign' }" class="text-decoration-none section-item-link">
                <div class="marketing-item">
                  <img src="@/assets/icons/icon-yard-sign.svg" alt />
                  <div class="marketing-item__title">Yard Sign: For Sale</div>
                  <div class="marketing-item__desc">Create your custom yard signs</div>
                </div>
              </router-link>
            </div>
            <div class="col-md-4 col-lg-3 mt-3 mt-3">
              <router-link :to="{ name: 'brands' }" class="text-decoration-none section-item-link">
                <div class="marketing-item">
                  <img src="@/assets/icons/icon-brands.svg" alt />
                  <div class="marketing-item__title">Brands</div>
                  <div class="marketing-item__desc">CMC Brand Resources</div>
                </div>
              </router-link>
            </div>
  <!--          <div class="col-md-6 col-lg-4 mt-3 mt-3">-->
  <!--            <a href="#" class="text-decoration-none section-item-link">-->
  <!--              <div class="section-item">-->
  <!--                <img src="@/assets/icons/icon-marketing help.svg" alt="">-->
  <!--                <div class="section-item-info">-->
  <!--                  <div class="section-item-title">Marketing Help</div>-->
  <!--                  <div class="section-item-subtitle">Have questions about campaigns?</div>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </a>-->
  <!--          </div>-->
            <div class="col-md-4 col-lg-3 mt-3 mt-3">
              <router-link :to="{name: 'training_videos'}" class="text-decoration-none section-item-link">
                <div class="marketing-item">
                  <img src="@/assets/icons/icon-training-videos.svg" alt />
                  <div class="marketing-item__title">Training Videos</div>
                  <div class="marketing-item__desc">Take in-depth training of Vision</div>
                </div>
              </router-link>
            </div>

            <div class="col-md-4 col-lg-3 mt-3 mt-3">
<!--                <router-link :to="{name: 'assets_creation'}" class="text-decoration-none section-item-link">-->
<!--                  <div class="marketing-item">-->
<!--                    <img src="@/assets/icons/wire-instructions-icon.svg" alt />-->
<!--                    <div class="marketing-item__title">Assets Creation</div>-->
<!--                    <div class="marketing-item__desc">Loan Officer Ad Request Form</div>-->
<!--                  </div>-->
<!--                </router-link>-->
            </div>
          </div>

          <!--Other category category--->
          <div class="marketing-tools" v-for="marketingResource in marketingResources" :key="marketingResource.id">
            <div class="marketing-category">
              <div class="marketing-title">{{marketingResource.title}}</div>
            </div>
            <div class="row" v-if="marketingResource.items.length > 0">
              <div class="col-md-4 col-lg-3 mt-3 mt-3" v-for="item in marketingResource.items" :key="item.id">
                <marketing-item :item="item" />
              </div>
            </div>
            <div class="row my-3 mx-1" v-else>
              No items found
            </div>
          </div>
        </div>
      </template>
    </base-page-content>
  </div>
</template>

<script>
import BasePageContent from "../../../components/BasePageContent.vue";
import BasePageHeader from "../../../components/BasePageHeader.vue";
import PageSpinner from '../../../components/pageSpinner.vue';
import MarketingItem from "./includes/MarketingItem.vue";
export default {
  components: { BasePageHeader, BasePageContent, MarketingItem, PageSpinner },
  name: "MarketingCenterMlo",
  data() {
    return {
      marketingResources: [],
      isAdmin: false,
      ui: {
        loading: false,
      }
    };
  },
  computed: {
    pageTitle() {
      return "Marketing Resources";
    },
    backLink() {
      return "resource_center";
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.$http.get(`/api/v1/marketing-resource/`)
        .then((res) => {
          this.marketingResources = res.data.data;
          this.isAdmin = res.data.security.configuration
          this.ui.loading = false;
      })
      .catch(() => {
        this.ui.loading = false;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.page-header {
  padding-top: 14px;
}
.page-content-breadcrumbs {
  padding: 11px 24px;
  border-bottom: 1px solid #e6e6e6;

  a,
  span {
    display: inline-block;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }

  a {
    color: #4d4d4d;
    text-decoration: none;
  }

  span {
    margin-left: 9px;
    color: #006bf6;

    &.delimeter {
      color: #4d4d4d;
    }
  }
}

.page-content {
  margin-top: 20px;
}

.marketing {
  &-category {
    margin-bottom: 4px;
  }
  &-title {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .section-title {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 24px;
  }

  .section-item-link {
    cursor: pointer;
    &.disabled {
      cursor: default;
      pointer-events: none;

      .section-item {
        opacity: .5;
      }
    }
  }

  .section-item {
    display: flex;
    align-items: center;
    border: 1px solid #DADADA;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 18px;
    transition: all .3s;

    &:hover {
      border: 1px solid #017AFF;
    }

    &-info {
      margin-left: 18px;
    }

    &-title {
      position: relative;
      width: fit-content;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 6px;

      &::after {
        position: absolute;
        display: block;
        content: '';
        bottom: 0;
        width: 0%;
        height: 1px;
        background: black;
        transition: all .3s;
      }
    }

    &-subtitle {
      color: rgba(0,0,0,.6);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
}
.marketing-item {
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 16px 20px;
  margin-bottom: 20px;
  transition: all 0.3s;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    border: 1px solid #017aff;
  }
  &__title {
    color: #000000;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    margin: 8px 0 4px 0;
  }
  &__desc {
    color: rgba($color: #000000, $alpha: 0.7);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }
}
</style>
